





















import { Component, Vue,Prop } from "vue-property-decorator";
@Component({
  components: {
  },
})
export default class RecyleDetailCard extends Vue {
    @Prop() item:any
    created(){
    }
}
