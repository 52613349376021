














































import { Component, Vue } from "vue-property-decorator";
import RecyleDetailCard from "@/views/recyle/components/RecyleDetailCard.vue";
import {getRecycle,postRecycle} from "@/api/user-supply.api";
import { Toast } from "vant";
import InteractionLib from "@/utils/interaction.lib";
@Component({
  components: {
    RecyleDetailCard,
  },
})
export default class RecyleDetail extends Vue {
  nowList: any = [];
  currentList:any = []
  totalPrice = 0;
  openProtocol() {
    if (InteractionLib.isApp()) {
      InteractionLib.openWebView(`${location.origin}/recyle-rule`);
      return;
    }
    return this.$router.push({ name: "recyleRule" });
  }
  async created() {
    this.nowList = await getRecycle(1,1,this.$route.query?.nowList as string);
    if (this.nowList.length <= 0) {
      return this.toRecyle();
    }
    this.nowList.forEach((item: any) => {
      this.totalPrice += item.recycle_price * 1000;
    });
  }
  toRecyle() {
    InteractionLib.updateGoldBlindBoxStatus();
    this.$router.go(-1)
  }
  async confirm() {
    await postRecycle((this.$route.query?.nowList as string).split(',').map(Number)).catch((err) => {
      Toast(err);
      throw err;
    });
    this.toRecyle();
    Toast("回收成功,可前往钱包查看蒸汽币余额");
  }
}
